const About = () => {
  return (
    <section className="section">
      {/* <h1>Про Комітет</h1> */}
      <div className="container">
        <article className="article">
          <h2>Місія комітету</h2>
          <ul>
            <li>
              <p>
                {
                  "Підготовка та проведення наукових форумів з теоретичної і прикладної механіки та суміжних наук."
                }
              </p>
            </li>
            <li>
              <p>
                {
                  "Сприяння координації наукових досліджень в галузі механіки, що виконуються вченими вітчизняних наукових установ, закладів вищої освіти та науково-виробничих підприємств."
                }
              </p>
            </li>
            <li>
              <p>
                {
                  "Сприяння впровадженню новітніх розробок в галузі механіки та суміжних наук у виробництво."
                }
              </p>
            </li>
            <li>
              <p>
                {
                  "Зміцнення зв’язків механіків України з закордонними вченими, а також організаціями та міжнародними товариствами з метою розвитку механіки."
                }
              </p>
            </li>
            <li>
              <p>
                {
                  "Представництво механіків України в Міжнародному союзі з теоретичної і прикладної механіки (IUTAM) та інших міжнародних організаціях з механіки і суміжних наук."
                }
              </p>
            </li>
            <li>
              <p>{"Розповсюдження науково-технічної інформації з механіки."}</p>
            </li>
          </ul>
        </article>
        <article className="article">
          <h2>Керівництво комітету</h2>

          <p>
            <span className="spanBolt">{"Голова: "}</span>
            Богданов Вячеслав Леонідович - академік НАН України, д.ф.-м.н.
          </p>
          <p>
            <span className="spanBolt">Заступники голови:</span>
            <ul>
              <li>
                Бобир Микола Іванович - член-кореспондент НАН України, д.т.н.
              </li>
              <li>Булат Анатолій Федорович - академік НАН України, д.т.н.</li>
              <li>
                Жук Ярослав Олександрович - член-кореспондент НАН України,
                д.ф.-м.н.
              </li>
              <li>Кушнір Роман Михайлович - академік НАН України, д.ф.-м.н.</li>
              <li>
                Назаренко Володимир Михайлович - академік НАН України, д.т.н.
              </li>
            </ul>
          </p>
          <p>
            <span className="spanBolt">{"Вчений секретар: "}</span>
            Селіванов Михайло Федорович - д.ф.-м.н.
          </p>
        </article>
        <article className="article">
          <h2>
            Президія
          </h2>

          <p>
            <a href="./pdf/povnprez.pdf" download="./pdf/povnprez.pdf">
            Члени Президії Національного комітету України з теоретичної і
            прикладної механіки та їх повноваження
            </a>
          </p>
        </article>
      </div>
      
    </section>
  );
};
export default About;
